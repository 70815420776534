import {useSearchResultStore} from "~/stores/searchResult.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {getSearchSpaceList} from "@/api/space";

export function useSearchWithStore() {
    const searchResultStore = useSearchResultStore()
    const searchQueryStore = useSearchQueryStore()


    async function handleSearchWithStore() {
        const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID.toString()
        const queryData = searchQueryStore.getSearchQueryDate

        const recommendQueryData = {
            "pagination": {
                "dir": 1,
                "limit": 20,
                "page": 1,
                "sort": "created_date"
            },
            filter_options: {
                city_ids: [],
                category_ids: {}
            },
            request_id: ""
        }

        try {
            const res= await getSearchSpaceList(queryData);

            if (res.total > 0) {

                searchResultStore.setSpaceList(res.list)
                searchResultStore.setRequestId(res.request_id)
                searchResultStore.setSpaceListTotal(res.total)
            } else {
                searchResultStore.setSpaceList([])
                searchResultStore.setRequestId('')
                searchResultStore.setSpaceListTotal(0)

                // 如果沒有搜尋結果，則推薦相關空間
                // 如果有選擇城市，則推薦相同城市的空間
                // 如果有選擇類別，則推薦相同類別的空間
                if (queryData.filter_options.city_ids.length !== 0) {
                    recommendQueryData.filter_options.city_ids = queryData.filter_options.city_ids
                } else if (queryData.filter_options.category_ids[typeCategoryId]?.length !== 0) {
                    recommendQueryData.filter_options.category_ids[typeCategoryId] = queryData.filter_options.category_ids[typeCategoryId]
                }

                /// 非 geo search 才有推薦空間
                if (searchQueryStore.getGeoSearch.latitude === 0 && searchQueryStore.getGeoSearch.longitude === 0) {
                    const {data: recommendSpaceListRes} = await getSearchSpaceList(recommendQueryData)
                    // 推薦空間一樣使用空間列表
                    searchResultStore.setSpaceList(recommendSpaceListRes.list)
                }
            }
            // todo 刪除用途搜尋的搜尋結果，之後搜尋結果頁面，設計好了要刪掉
            const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID
            searchQueryStore.clearCategoryObjectByKey(usageCategoryId)

        } catch (error) {
            console.log(error);
        }

    }


    return {
        handleSearchWithStore
    }
}

import {defineStore} from 'pinia'
import {ref, computed, watch} from 'vue';


export const useSearchQueryStore = defineStore(
    'searchQuery',
    () => {
        // state
        const request_id = ref('')
        const pagination = ref({
            dir: 1,
            limit: 20,
            page: 1,
            sort: ''
        })
        const filter_options = ref({
            keyword: '',
            rent_time: {
                rent_type: 0,
                begin_time: '',
                end_time: ''
            },
            geo_search: {
                longitude: 0,
                latitude: 0,
                width: 0,
                height: 0,
                radius: 0
            },
            equipment_ids: [],
            service_ids: [],
            city_ids: [],
            category_ids: {}
        })


        // getters
        const getSearchQueryDate = computed(() => {
            return {
                request_id: request_id.value,
                pagination: pagination.value,
                filter_options: filter_options.value
            }
        })

        const getRentTimeSelected = computed(() => filter_options.value.rent_time)
        const getSearchCityId = computed(() => filter_options.value.city_ids)
        const getSearchCategoryIds = computed(() => filter_options.value.category_ids)
        const getSearchKeyword = computed(() => filter_options.value.keyword)
        const getPagination = computed(() => pagination.value)
        const getEquipmentIds = computed(() => filter_options.value.equipment_ids)
        const getServiceIds = computed(() => filter_options.value.service_ids)
        const getGeoSearch = computed(() => filter_options.value.geo_search)


        // actions
        function initQueryDate() {
            request_id.value = ''
            pagination.value = {
                dir: 1,
                limit: 20,
                page: 1,
                sort: ''
            }
            filter_options.value = {
                keyword: '',
                rent_time: {
                    rent_type: 0,
                    begin_time: '',
                    end_time: ''
                },
                geo_search: {
                    longitude: 0,
                    latitude: 0,
                    width: 0,
                    height: 0,
                    radius: 0
                },
                equipment_ids: [],
                service_ids: [],
                city_ids: [],
                category_ids: {}
            }
        }

        function setRequestId(id) {
            request_id.value = id
        }

        function setPagination(inPagination) {
            pagination.value = {...pagination.value, ...inPagination}
        }

        // todo 是否需要 setFilterOptions
        function setFilterOptions(inFilterOptions) {
            filter_options.value = inFilterOptions
        }

        function setKeyword(keyword) {
            filter_options.value.keyword = keyword
        }

        function setRentTimeSelected(rentTime) {
            filter_options.value.rent_time = rentTime
        }

        function initGeoSearch() {
            filter_options.value.geo_search = {
                longitude: 0,
                latitude: 0,
                width: 0,
                height: 0,
                radius: 0
            }
            setPagination({sort: ''})
        }

        function setGeoSearch(geoSearch) {
            filter_options.value.geo_search = geoSearch
        }

        function setEquipmentIds(equipmentIds) {
            filter_options.value.equipment_ids = equipmentIds
        }

        function setServiceIds(serviceIds) {
            filter_options.value.service_ids = serviceIds
        }

        function setSelectedCityID(cityIDList) {
            clearCitySelected()
            filter_options.value.city_ids = cityIDList
        }

        function setSelectedCategoryObject(categorySelectObj) {
            const list = {...filter_options.value.category_ids, ...categorySelectObj}
            filter_options.value.category_ids = list
        }

        function clearRequestId() {
            request_id.value = ''
        }

        function clearPagination() {
            pagination.value = {
                dir: 1,
                limit: 20,
                page: 1,
                sort: ''
            }
        }

        function clearCitySelected() {
            filter_options.value.city_ids.length = 0
        }

        function clearCategoryObjectByKey(categoryKey) {
            delete filter_options.value.category_ids[categoryKey]
        }

        function clearKeyword() {
            filter_options.value.keyword = ''
        }

        function clearEquipmentIds() {
            filter_options.value.equipment_ids.length = 0
        }

        function clearServiceIds() {
            filter_options.value.service_ids.length = 0
        }


        watch(filter_options, (newFilterOptions) => {
            clearRequestId();
        }, {
            deep: true,
            immediate: true
        })


        return {
            request_id,
            pagination,
            filter_options,
            getSearchQueryDate,
            getRentTimeSelected,
            getSearchCityId,
            getSearchCategoryIds,
            getSearchKeyword,
            getPagination,
            getEquipmentIds,
            getServiceIds,
            getGeoSearch,
            initQueryDate,
            setRequestId,
            setPagination,
            setFilterOptions,
            setKeyword,
            setRentTimeSelected,
            initGeoSearch,
            setGeoSearch,
            setEquipmentIds,
            setServiceIds,
            setSelectedCityID,
            setSelectedCategoryObject,
            clearRequestId,
            clearPagination,
            clearCitySelected,
            clearCategoryObjectByKey,
            clearKeyword,
            clearEquipmentIds,
            clearServiceIds
        }
    },
    {
        persist: {
            storage: persistedState.sessionStorage
        }
    }
)
